<template>
  <section class="vueVideo">
    <!-- <div
      v-video-player:myVideoPlayer="playerOptions"
      class="video-player-box"
    ></div> -->
    <video-player
      class="video-player vjs-custom-skin"
      ref="myVideoPlayer"
      :playsinline="true"
      :options="playerOptions"
    ></video-player>
  </section>
</template>

<script>
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
export default {
  name: "myVideoPlayer",
  props: {
    videoSrc: {
      type: String,
      default: "",
    },
    posterSrc: {
      type: String,
      default: "https://food-fair.oss-cn-beijing.aliyuncs.com/logo/banner2025.jpg",
    },
  },
  data() {
    return {
      img:require("../assets/img/video_back.jpg"),
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: this.videoSrc, // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../assets/img/video_back.jpg", //你的封面地址
        poster: this.posterSrc, // 你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  methods: {},
  created() {},
  watch: {
    videoSrc: {
      handler(v) {
        // console.log(this.posterSrc);
        // console.log(this.playerOptions.sources[0].src )
        this.playerOptions.sources[0].src = v;
        this.$forceUpdate();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.video-player_mainCon {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.video-player_mainCon /deep/ .vjs-poster {
  background-size: 100% 100%;
}

.video-player .vjs-has-started .vjs-control-bar {
  padding-right: 40px;
}
.video-player {
  width: 100%;
  /* height: 239px; */
  display: inline-block;
}
.download_btn_show {
  bottom: 20px;
  right: 0;
  color: white;
}
.video-js .vjs-big-play-button {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 55px;
}
.vjs-custom-skin > .video-js .vjs-big-play-button{
  height: 1.65em!important;
  line-height: 1.65em!important;
  border-radius: 50%;
}
.video-js:hover .vjs-big-play-button{
  background-color: rgba(0,0,0,0.6);
}
</style>
