<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <div class="row banner" style="height: 160px">
          <div class="banner-content col-lg-12 col-md-12">
            <h1 class="banner_title">主题展区</h1>
            <h4 class="banner_desc">
              第22届中国（漯河）食品博览会共设置十大展区
            </h4>
          </div>
        </div>
      </div>
    </section>
    <section class="search">
      <div class="container">
        <div class="search_con">
          <div class="input-group flex-nowrap">
            <div class="input-group-prepend">
              <span
                class="input-group-text iconfont iconsearch"
                id="addon-wrapping"
              ></span>
            </div>
            <input
              v-model="key"
              type="text"
              class="form-control"
              placeholder="请输入展品关键字"
              aria-label="Username"
              aria-describedby="addon-wrapping"
            />
            <div class="but">
              <span @click="searchData">搜索</span>
              <span @click="resetData">重置</span>
            </div>
          </div>
        </div>
        <p  v-if="!typeFlag && data.length > 0">以下是为您搜索到的结果</p>
      </div>
    </section>
    <section class="group_section relative" v-if="typeFlag">
      <div class="container" style="padding: 0">
        <div class="group">
          <div class="group_title">请选择分类</div>
          <ul class="group_list">
            <li
              :class="activeIndex == index ? 'active' : ''"
              v-for="(item, index) in typeList"
              :key="index"
              @click="changeTypeIndex(item.name, index)"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <!-- <div class="banner-content col-lg-12 col-md-12">
            <h1 class="banner_title">主题展区</h1>
            <h4 class="banner_desc">
              第20届中国（漯河）食品博览会共设置九大展区
            </h4>
          </div> -->
        </div>
      </div>
    </section>
    <section class="list_section relative">
      <div class="container">
        <div class="list" v-if="data.length > 0">
          <ul class="row">
            <li
              class="col-lg-3 col-6"
              v-for="(item, index) in data"
              :key="index"
            >
              <div class="list_con">
                <div @click="openWeb('/proDetail?id=' + item.id)" class="skip">
                  <div class="image">
                    <div :style="{ backgroundImage: `url(${item.path[0]})` }">
                      <!-- <img :src="item.path" alt="" /> -->
                    </div>
                  </div>
                  <p class="title">
                    {{ item.name }}
                  </p>
                </div>
                <div class="type_but">
                  <span>{{ item.productType }}</span>
                </div>
                <div
                  class="name"
                  @click="openWeb('/exhibitorDetails?id=' + item.exhibitorsId)"
                >
                  <div>
                    <i class="iconfont iconshangjia"></i>
                    <span>商家</span>
                  </div>
                  <p>{{ item.exhibitorsName }}</p>
                </div>
                <div class="area">
                  <div>
                    <i class="iconfont iconditu"></i>
                    <span>展位</span>
                  </div>
                  <p>{{ item.exhibitorsBooth }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="no_more" v-else>
          <i class="iconfont iconweb__zanwujilu"> </i>
          <p>数据正在更新中，请持续关注哟~</p>
        </div>
        <div
          class="more"
          @click="addCur"
          v-if="total > 16 && total != data.length"
        >
          <div>
            <i class="iconfont iconjiazai"></i>
            <span>加载更多</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  getExhibitorsProduct,
  getexhibitorsProductInfoBySearch,
} from "@/api/index.js";
export default {
  data() {
    return {
      banner: require("@/assets/img/displayArea/banner.jpg"),
      activeIndex: 0,
      typeList: [
        {
          name: "综合展区",
          url: require("@/assets/img/home/type1.png"),
        },
        {
          name: "国际食品展区",
          url: require("@/assets/img/home/type2.png"),
        },
        {
          name: "品牌企业展区",
          url: require("@/assets/img/home/type3.png"),
        },
        {
          name: "美食体验专区",
          url: require("@/assets/img/home/type4.png"),
        },
        {
          name: "休闲食品展区",
          url: require("@/assets/img/home/type5.png"),
        },
        {
          name: "烘焙食品展区",
          url: require("@/assets/img/home/type6.png"),
        },
        {
          name: "酒水饮料",
          url: require("@/assets/img/home/type7.png"),
        },
        {
          name: "功能食品展区",
          url: require("@/assets/img/home/type8.png"),
        },
        {
          name: "食品辅料展区",
          url: require("@/assets/img/home/type9.png"),
        },
        {
          name: "食品包装机械展区",
          url: require("@/assets/img/home/type10.png"),
        },
      ],
      data: [
        /* 
        {
          type: "综合展区",
          title:
            "商品名称限制两行商品名称限制两行商品名称限制两行大约38个字...",
          name: "河南**有限公司食品发展部",
          area: "AA-1003",
          image: require("@/assets/img/displayArea/food.jpg"),
        },
        {
          type: "综合展区",
          title:
            "商品名称限制两行商品名称限制两行商品名称限制两行大约38个字...",
          name: "河南**有限公司食品发展部",
          area: "AA-1003",
          image: require("@/assets/img/displayArea/food.jpg"),
        },
       */
      ],
      productType: "综合展区",
      form: {
        size: 16,
        current: 1,
      },
      total: "",
      key: "",
      typeFlag: true,
    };
  },
  methods: {
    resetData() {
      this.typeFlag = true;
      this.productType = "综合展区";
      this.key = "";
      this.getData();
    },
    searchData() {
      if (this.key) {
        this.typeFlag = false;
        getexhibitorsProductInfoBySearch({ keyword: this.key }).then((res) => {
          if (res.success) {
            res.result.records.forEach((item) => {
              item.path = JSON.parse(item.path);
            });
            this.data = res.result.records;
            this.total = res.result.total;
          } else {
            this.data = [];
          }
        });
      } else {
        this.key = "请输入您要搜索的内容哦";
      }
    },
    openWeb(v) {
      this.$router.push(v);
    },
    addCur() {
      this.form.size = this.form.size + 16;
      this.getData();
    },
    changeTypeIndex(v, i) {
      this.activeIndex = i;
      this.productType = v;
      this.getData();
    },
    getData() {
      getExhibitorsProduct(this.productType, this.form).then((res) => {
        if (res.success) {
          res.result.records.forEach((item) => {
            item.path = JSON.parse(item.path);
          });
          this.data = res.result.records;
          this.total = res.result.total;
        } else {
          this.data = [];
        }
      });
    },
  },
  created() {
    this.getData();
  },
  watch: {
    $route: {
      handler() {
        // console.log(this.$route.query.type);
        if (this.$route.query.type) {
          this.typeList.forEach((item, index) => {
            if (item.name == this.$route.query.type) {
              this.changeTypeIndex(item.name, index);
            }
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  div.banner {
    .banner-content {
      text-align: left;
      margin: 0;
    }
    .banner_title {
      //   font-size: 37px;
      color: #ffffff;
      margin-top: 25px;
    }
    .banner_desc {
      //   font-size: 16px;
      color: #ffffff;
    }
  }
}
.group_section {
  .group {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin: 20px 6px;
    .group_title {
      text-align: left;
      font-size: 16px;
      color: #3b3b3b;
      background-color: #f9f9f9;
      padding: 10px 20px;
    }
    ul.group_list {
      text-align: left;
      li {
        padding: 10px;
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
        &:hover,
        &.active {
          color: #eb6e15;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
.list_section {
  .list {
    ul {
      li {
        margin-bottom: 10px;
        &.col-lg-3 {
          padding: 0 4px;
        }
        .list_con {
          border: 1px solid #eeeeee;
          overflow: hidden;
          &:hover {
            box-shadow: 0px 0px 6px 0px rgba(39, 39, 39, 0.16);
            i {
              color: #3f6ce2;
            }
            img {
              transform: scale(1.1);
              transition: all 0.5s;
            }
          }
        }
        .skip {
          cursor: pointer;
          .image {
            height: 215px;
            padding: 10px;
            div {
              min-height: 200px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              border: 1px solid #eee;
            }
            img {
              width: 100%;
              padding: 10px;
              transform: scale(1);
              transition: transform 0.5s;
            }
          }
          p.title {
            font-size: 14px;
            color: #363636;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0px;
            padding: 4px 10px;
            text-align: left;
          }
        }
        .type_but {
          text-align: left;
          padding: 5px 10px;
          span {
            text-align: center;
            border: 1px solid #eeeeee;
            border-radius: 4px;
            display: inline-block;
            padding: 2px 10px;
            font-size: 14px;
            color: #ff6600;
          }
        }
        .name {
          border-bottom: 1px solid #eee;
          cursor: pointer;
        }
        .name,
        .area {
          background-color: #f9f9f9;
          text-align: left;
          padding: 5px 10px;
          i {
            font-size: 14px;
            color: #ababab;
          }
          span {
            font-size: 14px;
            color: #ababab;
            margin-left: 5px;
          }
          p {
            margin-bottom: 0;
            color: #373139;
            font-size: 14px;
          }
        }
      }
    }
  }
  .no_more {
    height: 40vh;
    padding-top: 100px;
    color: #ddd;
    i {
      font-size: 100px;
    }
    p {
      margin: 20px;
    }
  }
  .more {
    margin: 10px 0;
    div {
      display: inline-block;
      padding: 6px 30px;
      border: 1px solid #ff6600;
      border-radius: 8px;
      cursor: pointer;
      i {
        color: #ff6600;
      }
      span {
        font-size: 16px;
        color: #ff6600;
      }
    }
  }
}
.search {
  margin-top: 10px;
  margin-bottom: 20px;
  p{
    color: #999;
    margin: 20px 0;
    font-size: 14px;
  }
  .container {
    padding: 0 6px;
  }
  .search_con {
    // background: linear-gradient(-6deg, #fa8631, #ffde8c);
    background-image: url(../../assets/img/search_back.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    .input-group {
      width: 70%;
      margin: 0 auto;
    }
    .input-group-prepend {
      span {
        font-size: 1.5rem;
        line-height: 1rem;
        color: #cfcfcf;
      }
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #ccc;
    }
    .input-group-text {
      background-color: #fff;
      border-right: none;
    }
    .form-control {
      border-left: none;
      outline: none;
      border-radius: 0 4px 4px 0 !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .but {
      vertical-align: middle;
      span {
        background: #ffffff;
        border-radius: 4px;
        font-size: 16px;
        color: #eb7015;
        line-height: 26px;
        padding: 6px 40px;
        height: 100%;
        margin: 0 0 0 10px;
        display: inline-block;
        cursor: pointer;
        font-weight: 600;
        &:nth-child(2){
          color:#999;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .search {
    .search_con {
      .input-group {
        width: 95% !important;
      }
      .but {
        span {
          padding: 6px 10px !important;
        }
      }
    }
  }
}
</style>